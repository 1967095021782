import logo from '../logo.svg';
import { useState } from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import '../App.css';

//PropTypes
import PropTypes from 'prop-types';

//props -> alt, beforeSrc, afterSrc
function Home(props) {

  const location = useLocation();
  console.log(location);

  //画像設定
  const [current, setCurrent] = useState(props.beforeSrc);
  //onMouseで画像切り替え
  const handleEnter = () => setCurrent(props.afterSrc);
  const handleLeave = () => setCurrent(props.beforeSrc);
  
  //画像エラー
  const handleError = () => setCurrent('./noimage.jpg');

  return (
    <div className="App">

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>サイトホームです。</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <h1>ホームページ {location.pathname}</h1>
        <p>現在のパス: {location.pathname}</p>
        <p>検索パラメータ: {location.search}</p>
		<p><img src={current} alt={props.alt} onMouseEnter={handleEnter} onMouseLeave={handleLeave} onError={handleError} /></p>
      </header>

	</div>
  );
}

Home.propTypes = {
	alt: PropTypes.string.isRequired,
	beforeSrc: PropTypes.string.isRequired,
	afterSrc: PropTypes.string.isRequired

};

export default Home;
