import React from 'react';
import {useState} from 'react';
import '../App.css';

/* 登録数 p.185 */
let maxId = 0;

function ModArray() {

  /* 初期設定 */
  const [title,setTitle] = useState('');
  const [todo,setTodo] = useState([]);

  /* 追加 */
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleClick = () => {
    setTodo([
      ...todo,
      {
        id: ++maxId,
        title,
        created: new Date(),
        isDone: false
      }
    ]);
  };

  /* 済 p.187 */
  const handleDone = (e) => {
    setTodo(todo.map(item => {
      if( item.id === Number(e.target.dataset.id)){
        return {
          ...item,
          isDone: true
        };
      }else{
          return item;
      }
    }));
  };

  /* 削除 p.189 */
  const handleRemove = (e) => {
    setTodo(todo.filter(item =>
      item.id !== Number(e.target.dataset.id)
    ));
  };

  /* ソート p.191 */
  const [desc, setDesc] = useState(true);

  const handleSort = (e) => {
    const sorted = [...todo];
    sorted.sort((m,n) => {
      if(desc){
        return n.created.getTime() - m.created.getTime();
      }else{
        return m.created.getTime() - n.created.getTime();
      }
    });

    // desc反転
    /*
    if(desc){
      setDesc(false);
    }else{
      setDesc(true);
    }
    */
    setDesc(d => !d);
    setTodo(sorted);

  };



  return (
    <form>
    <div id="ModArray">
		<h1>配列更新</h1>

      <label>やること：
        <input type="text" id="title" name="title"
        value={title} onChange={handleChangeTitle} />
      </label>

      <button type="button"
        onClick={handleClick}>追加する</button>
      <button type="button"
        onClick={handleSort}>
          ソート ( {desc ? '↑' : '↓'} )
        </button>
      <hr/>

      <ul>
        {todo.map(item => (
          <li key={item.id}
            className={item.isDone ? 'done':''}
          >{item.title}
          <button type="button"
            onClick={handleDone} data-id={item.id}>済</button>
          <button type="button"
            onClick={handleRemove} data-id={item.id}>削除</button>
          </li>
        ))}
      </ul>

    </div>
    </form>
  );
}


export default ModArray;
