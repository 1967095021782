import { useState } from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import '../App.css';

//StateCounter p.131
import StateCounter from './StateCounter';


function About({ type, init, FileLists }) {

  //状態取得 https://www.wantedly.com/companies/company_1071330/post_articles/865783
  const location = useLocation();
  console.log(location);

  //現時刻 p.87 + p.145 
  const[nowTime,setNowTime] = useState();
  const current = (e, type) => {
	  const d = new Date();
	  switch(type) {
		case 'date':
			console.log(d.toLocaleDateString());
			setNowTime(d.toLocaleDateString());
			break;
		case 'time':
			console.log(d.toLocaleTimeString());
			setNowTime(d.toLocaleTimeString());
			break;
		default:
			console.log(d.toLocaleString());
			setNowTime(d.toLocaleString());
			break;
	  }
  };


  //State カウント p.89
  const [count, setCount] = useState(init);
  const handleClick = () => setCount(count +1);

  //StateCounter p.131
  const update = step => setCount(c => c + step);


  return (
    <div>
	  <h1>About</h1>
	  <p id="jqueryComment"></p>
        <h1>ホームページ {location.pathname}</h1>
        <p>現在のパス: {location.pathname}</p>
        <p>検索パラメータ: {location.search}</p>
		<p><button onClick={current}>現在時刻を取得{nowTime}</button></p>
		<p>
			<button onClick={handleClick}>カウント</button><br />
			{count}回、クリックされました。
		</p>
		<p>
			<StateCounter step={1} onUpdate={update} />
			<StateCounter step={5} onUpdate={update} />
			<StateCounter step={-1} onUpdate={update} />
		</p>
		{/* p.145 */}
		<p>
			<button id="dt" onClick={e => current(e, 'datetime')}>現在日時</button>
			<button id="date" onClick={e => current(e, 'date')}>現在日付</button>
			<button id="time" onClick={e => current(e, 'time')}>現在時刻</button>
		</p>
	</div>
  );
}

export default About;
