import React from 'react';
import {useState} from 'react';
import '../App.css';

import {useRef} from 'react';

function SimpleForm() {

  /* p.158 初期設定 */
  const[form, setForm] = useState({
    name:'山田太郎',
    age: 18,
    animal: 'rabbit',
    animals: ['dog','rabbit'],
    os: 'windows',
    agreement: true,
    food:['apple','lemon']
  });

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  };

  /* p.168 multiple */
  const handleFormList = e => {
    const data = [];
    const opts = e.target.options;
    for (const opt of opts) {
      if (opt.selected){
        data.push(opt.value);
      }
    }
    setForm({
      ...form,
      [e.target.name]: data
    })
  };

  /* p.171 checkbox単一 */
  const handleFormCheck = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked
    })
  };

  /* p.173 checkbox複数 */
  const handleFormMulti = e => {
    const ff = form.food;
    if (e.target.checked){
      ff.push(e.target.value);
    }else{
      ff.splice(ff.indexOf(e.target.value),1);
    }

    setForm({
      ...form,
      [e.target.name]: ff
    })
  }

  /* p.174 file */
  const file = useRef(null);

  const show = () =>{
    // console.log(`こんにちわ、${form.name} （${form.age}）さん！`);
    // console.log(`好きな動物は ${form.animal} です。`);
    // console.log(`好きな動物2 ${form.animals} です。`);
    // console.log(`使用OSは、${form.os}です。`);
    console.log(`同意確認：${form.agreement ? '同意します':'同意しません'}`);
    console.log(`好きな食べ物：${form.food}`);

    const fs = file.current.files;
    //取得したファイル群を順に走査
    for(const f of fs){
      console.log(`ファイル名：${f.name}`);
      console.log(`種類：${f.type}`);
      console.log(`サイズ：${Math.trunc(f.size / 1024)}KB`);
    }

  };


  /* p.161 ref */
  const refname = useRef(null);
  const refage  = useRef(null);
  const refShow = () => {
    console.log(`こんにちわ、${refname.current.value} （${refage.current.value}）さん`);
  };


  return (
    <form>
    <div id="SimpleForm">
		<h1>state Form</h1>

      <div>
        <label htmlFor='name'>名前：</label>
        <input type="text" id="name" name="name" value={form.name} onChange={handleForm}></input>
      </div>

      <div>
        <label htmlForm="age">年齢：</label>
        <input type="text" id="age" name="age" value={form.age} onChange={handleForm}></input>
      </div>

      <div>
        <label htmlFor="animal">好きな動物：</label>
        <select id="animal" name="animal" value={form.animal} onChange={handleForm}>
          <option value="dog">イヌ</option>
          <option value="cat">ネコ</option>
          <option value="hamster">ハムスター</option>
          <option value="rabbit">ウサギ</option>
        </select>
      </div>

      <div>
        <label htmlFor="animals">好きな動物2：</label>
        <select id="animals" name="animals" 
          value={form.animals} 
          onChange={handleFormList}
          size="4"
          multiple={true}>
          <option value="dog">イヌ</option>
          <option value="cat">ネコ</option>
          <option value="hamster">ハムスター</option>
          <option value="rabbit">ウサギ</option>
        </select>
      </div>

      <div>
        <fieldset>
        <legend>使用OS：</legend>
        <ul>
          <li><label htmlFor="os_win">Windows</label>
          <input type="radio" id="os_win" name="os"
           value="windows"
           checked={form.os === 'windows'}
           onChange={handleForm}
         ></input></li>

          <li><label htmlFor="os_mac">Mac</label>
          <input type="radio" id="os_mac" name="os"
           value="mac"
           checked={form.os === 'mac'}
           onChange={handleForm}
         ></input></li>

          <li><label htmlFor="os_linux">Linux</label>
          <input type="radio" id="os_linux" name="os"
           value="linux"
           checked={form.os === 'linux'}
           onChange={handleForm}
         ></input></li>
         </ul>
         </fieldset>
      </div>

      <div>
        <label htmlFor="agreement">同意します：</label>
        <input type="checkbox" id="agreement" name="agreement"
        checked={form.agreement}
        onChange={handleFormCheck}
        ></input>
      </div>

      <div>
        <fieldset>
          <legend>好きな食べ物</legend>
            <label htmlFor="food_apple">りんご</label>
            <input type="checkbox" id="food_apple" name="food"
            value="apple"
            checked={form.food.includes('apple')}
            onChange={handleFormMulti}
            ></input><br/>

            <label htmlFor="food_orange">みかん</label>
            <input type="checkbox" id="food_orange" name="food"
            value="orange"
            checked={form.food.includes('orange')}
            onChange={handleFormMulti}
            ></input><br/>

          <label htmlFor="food_lemon">レモン</label>
            <input type="checkbox" id="food_lemon" name="food"
            value="lemon"
            checked={form.food.includes('lemon')}
            onChange={handleFormMulti}
            ></input><br/>

        </fieldset>
      </div>

      <div>
        <input type="file" ref={file} multiple />
      </div>


      <div>
        <button type="button" onClick={show}>送信する</button>
      </div>
      <p>こんにちわ、{form.name} （{form.age}）さん</p>
	  </div>





    <div id="refForm">
      <h1>ref Form</h1>
      <div>
        <label htmlForm="refName">名前：</label>
        <input type="text" id="refname" name="refname" defaultValue="播磨太郎" ref={refname}></input>
      </div>
      <div>
        <label htmlFor="refAge">年齢：</label>
        <input type="text" id="refage" name="refage" defaultValue="18" ref={refage}></input>
      </div>
      <div>
        <button type="button" onClick={refShow}>送信する</button>
      </div>
    </div>
    </form>
  );
}


export default SimpleForm;
