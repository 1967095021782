import { useState } from 'react';
import { useEffect } from 'react';
//ページ遷移 react-router-dom
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

//className p.112
import  cn from 'classnames';


import './App.css';

//画像
import boforeImg from './img_a.jpg';
import afterImg from './img_b.jpg';


//ページ情報取得
import About from './components/About';
import Home from './components/Home';
import Works from './components/Works';
import List from './components/List';
import ListFilter from './components/ListFilter';
import SimpleForm from './components/SimpleForm';
import SimpleFormImmer from './components/SimpleFormImmer';
import ModArray from './components/ModArray';
import FormYup from './components/FormYup';
import FormYupPost from './components/FormYupPost';
import Footer from './components/Footer';

//Button
import Button from '@mui/material/Button';

//Icon
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import ListIcon from '@mui/icons-material/List';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';


//リスト一覧 - books p.97 
import FileLists from './FileLists';


function App({init="light"}) {

// express
const [message, setMessage] = useState('');
useEffect(() => {
    //fetchでバックエンドExpressのサーバーを指定
	//fetch で接続する際、package.json に node.js 雪像ｋ先の proxy追加。（例："proxy": "http://localhost:3001",）
	//proxy 追加後、以下のfetch の接続先を相対パスにしてもＯＫ。
	// /api -> backend/index.js の api のjson取得。 sql -> backend/index.js -> DB接続しデータ取得
    fetch('/api')
      //レスポンスをjsonとして受け取りjsオブジェクトを生成
      .then((res) => res.json())
      //生成したjsオブジェクトをdataに代入
      //data.messageで取り出したデータをuseStateに保存
      .then((data) => setMessage(data.message));
  },[]);


//ModeStyle (ダークモード変更）
  const [ModeStyle, setModeStyle] = useState(init);
  const handleClick = () => {

	  switch(ModeStyle) {
		case 'light':
			setModeStyle("dark");
			break;
		default:
			setModeStyle("light");
			break;
	  }

  };

  return (

    <BrowserRouter>

      <div className={cn(
		'App',
		{
			lightMode: ModeStyle === 'light',
			darkMode: ModeStyle === 'dark'
		}
	  )}>
	{/* ${ModeStyle === 'dark' ? 'darkMode' : 'lightMode'}`}> */}

	    <nav>
			<ul>
				<li><Link 
					to="/" 
					className="App-link"
					state={{
						linkData: "LinkHome"
					}}
				><Button><HomeIcon className="ButtonLink" />ホーム</Button></Link></li>
				<li><Link 
					to="/About" 
					className="App-link"
					state={{
						linkData: "LinkAbount"
					}}
				><Button><InfoIcon className="ButtonLink" />このサイトについて</Button></Link></li>
				<li><Link 
					to="/Works" 
					className="App-link"
					state={{
						linkData: "Works"
					}}
				><Button><WorkIcon className="ButtonLink" />実績紹介</Button></Link></li>
				<li><Link 
					to="/List" 
					className="App-link"
					state={{
						linkData: "List"
					}}
				><Button><ListIcon className="ButtonLink" />リスト一覧</Button></Link></li>
				<li><Link 
					to="/ListFilter" 
					className="App-link"
					state={{
						linkData: "ListFilter"
					}}
				><Button><FilterAltIcon className="ButtonLink" />リストフィルター</Button></Link></li>
				<li><Link 
					to="/SimpleForm" 
					className="App-link"
					state={{
						linkData: "SimpleForm"
					}}
				><Button><FilterAltIcon className="ButtonLink" />基本フォーム</Button></Link></li>
				<li><Link 
					to="/SimpleFormImmer" 
					className="App-link"
					state={{
						linkData: "SimpleFormImmer"
					}}
				><Button><FilterAltIcon className="ButtonLink" />Immerフォーム</Button></Link></li>
				<li><Link 
					to="/ModArray" 
					className="App-link"
					state={{
						linkData: "ModArray"
					}}
				><Button><ChecklistIcon className="ButtonLink" />配列追加更新削除</Button></Link></li>
				<li><Link 
					to="/FormYup" 
					className="App-link"
					state={{
						linkData: "FormYup"
					}}
				><Button><FilterAltIcon className="ButtonLink" />FormYup</Button></Link></li>
				<li><Link 
					to="/FormYupPost" 
					className="App-link"
					state={{
						linkData: "FormYupPost"
					}}
				><Button><FilterAltIcon className="ButtonLink" />FormYupPost</Button></Link></li>
			</ul>
			<p id="ModeButton"><button onClick={handleClick}>モード変更</button></p>
			<p id="ExpressDate">expressのデータは、{message} です。</p>
			<Routes>
			  <Route path="/" element={<Home beforeSrc={boforeImg} afterSrc={afterImg} alt="ロゴ画像" />} />
			  <Route path="/About" element={<About type="time" init={0} />} />
			  <Route path="/Works/" element={<Works />} />
			  <Route path="/List/" element={<List FileLists={FileLists} />} />
			  <Route path="/ListFilter/" element={<ListFilter FileLists={FileLists} />} />
			  <Route path="/SimpleForm/" element={<SimpleForm />} />
			  <Route path="/SimpleFormImmer/" element={<SimpleFormImmer />} />
			  <Route path="/ModArray" element={<ModArray />} />
			  <Route path="/FormYup" element={<FormYup />} />
			  <Route path="/FormYupPost" element={<FormYupPost />} />

			</Routes>
		</nav>

		<Footer myName="フッター" />
      </div>
    </BrowserRouter>

  );
}

export default App;
