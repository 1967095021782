import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import '../App.css';

//FormCustomize
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';


// 独自ルールyup p.210
yup.addMethod(yup.string, 'ng', function(){
	return this.test('ng',
		({label}) => `${label}に、不適切なワードが含まれています。`,
		value => {
			const ngs = ['暴力','死','グロ'];
			for( const ng of ngs ){
				if( value.includes(ng)){
					return false;
				}
			}
			return true;
		}
	);
});



// 検証ルールyup p.204
const schema = yup.object({
	userName: yup
		.string()
		.label('お名前')
		.required('${label}は必須入力です。')
		.min(2, '${label}は${min}文字以上で入力してください。')
		.max(256, '${label}は${max}文字以内で入力してください。')
		.transform((value,orgValue) => value.normalize('NFKC'))
		.ng(),
	userMail: yup
		.string()
		.label('メールアドレス')
		.required('${label}は必須入力です。')
		.email('${label}の形式が不正です。')
		.min(10,'${label}は${min}文字以上で入力してください。')
		.max(256, '${label}は${max}文字以内で入力してください。')
		.trim(),
	gender: yup
	.string()
	.label('性別')
	.required('${label}は必須入力です。')
});




function FormYupPost() {
//ページ遷移
const[pset,setPset] = useState(false);

// express -> ex はメッセージ。exarはmysqlからデータ配列取得
const [ex, setEx] = useState('');
const [exar, setExar] = useState([]);
useEffect(() => {

	fetch('/sql')
	.then((res) => res.json())
	.then((data) => {
		setExar(data.results);
		console.log(data.results);
	});

},[]);

//form 初期化 ;/ isDirty isValid isSubmitting p.203
const {
	register,
	handleSubmit,
	watch,
	formState: { 
		errors,
		isDirty,
		isValid,
		isSubmitting
	}
} = useForm({
	defaultValues: {
		userName: '',
		userMail: '',
		gender: ''
	},
	resolver: yupResolver(schema)
});

// 送信する
const onSubmit = (data) => {
	return new Promise(resolve => {
		//express Data-Select
		fetch('/api')
		.then((res) => res.json())
		.then((data) => setEx(data.message));

		setTimeout(() => {
			resolve();
			console.log(data);
			//alert(JSON.stringify(data));

			//Data-Insert
			/*
			fetch('/sql/insert',{
				method: 'POST',
				headers:{
					'Content-Type':'application/json'
				},
				body: JSON.stringify(data)
			})
			.then((res) => res.json())
			.then((data) => {
				console.log("InsertSuccess");
				setEx(data.message);
			});
			*/


			setPset(true);
		}, 1000);
	});
}

// エラー
const onError = err => console.log(err);

const [gender, setGender] = React.useState('');

const handleChange = (event) => {
	setGender(event.target.value);
};

  return (
    <div id="FormYumPost">
		<h1>FormYUPPOST</h1>
    
		<form onSubmit={handleSubmit(onSubmit)}>
			{ ( !pset ) &&
			<div id="FormStart">
				<ul>
					<li><TextField 
						id="userName" 
						label="お名前" 
						variant="outlined"
						{...register("userName")}
						name="userName"
					/>
					{errors.userName?.message && <span className="error"><br />{errors.userName.message}</span>}
					</li>
					<li><TextField 
						id="userMail" 
						label="メールアドレス" 
						variant="outlined" 
						{...register("userMail")} 
						name="userMail"
					/>
					{errors.userMail?.message && <span className="error"><br />{errors.userMail.message}</span>}
					</li>
					<li><Box sx={{ minWidth: 223 }}>
						<FormControl sx={{ minWidth: 223 }}>
							<InputLabel id="selectGenderLabel">性別</InputLabel>
							<Select
							{...register("gender")}
							labelId="selectGenderLabel"
							id="selectGender"
							value={gender}
							label="gender"
							onChange={handleChange}
							>
							<MenuItem value={''}>選択</MenuItem>
							<MenuItem value={'男性'}>男性</MenuItem>
							<MenuItem value={'女性'}>女性</MenuItem>
							<MenuItem value={'未選択'}>未選択</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{errors.gender?.message && <span className="error"><br />{errors.gender.message}</span>}
					</li>
				</ul>

				<p><Button 
					type="submit"
					disabled={ !isDirty || !isValid || isSubmitting }
				>送信する</Button></p>
				{isSubmitting && <div>送信中 {ex}</div>}
			</div>
			}
			{ (pset) &&
				<div>送信完了 {ex}</div>
			}

			<div id="SelectBox">
				<ul>
				{Object.values(exar).map((val,index) =>{
					return (
					<>
						<li><ul>
							<li key={index}>{val.id}</li>
							<li>{val.name}</li>
							<li>{val.email}</li>
							<li>{val.gender}</li>
							<li>{val.age}</li>
							<li>{val.regdate}</li>
						</ul></li>
					</>
					)
				})}
				</ul>
			</div>

		</form>
	</div>
  );
}


export default FormYupPost;
