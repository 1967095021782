import { Link, withRouter, useLocation } from "react-router-dom";
import '../App.css';



function Footer({myName}) {

  const location = useLocation();
  console.log(location);

  return (
    <div className="Footer">
	  <h1>データは{myName}です。</h1>
	</div>
  );
}

export default Footer;
