//books p.97
const FileLists = [
	{
		id: 1,
		isbn: '978-4815613365',
		title: 'これからはじめるVue.js 3実践入門',
		price:3740,
		summary:'JavaScriptフレームワーク[Vue.js]について...',
		download: true,
		url: 'https://wings.msn.to/index.php/-/A-03/978-4-8156-1336-5/',
	},{
		id: 2,
		isbn: '978-4815619480',
		title: 'これからはじめるReact実践入門 コンポーネントの基本からNext.jsによるアプリ開発まで',
		price:4000,
		summary:'React/Next.jsによるWebアプリケーション開発で...',
		download: true,
		url: 'https://wings.msn.to/index.php/-/A-03/978-4-8156-1948-0/',
	},{
		id: 3,
		isbn: '978-4798063980',
		title: 'React.js&Next.js超入門 第2版',
		price:3000,
		summary:'React.jsの入門者向け書籍です。2019/3発刊「React.js&Ne...',
		download: false,
		url: 'https://wings.msn.to/index.php/-/A-03/978-4-8156-1948-0/',
	},{
		id: 4,
		isbn: '978-4815610722',
		title: 'モダンJavaScriptの基本から始める React実践の教科書',
		price:2860,
		summary:'Reactをイチから学びたい方にオススメの1冊です!',
		download: true,
		url: 'https://wings.msn.to/index.php/-/A-03/978-4-8156-1948-0/',
	},{
		id: 5,
		isbn: '978-4798184678',
		title: '動かして学ぶ！Next.js/React開発入門',
		price:3520,
		summary:'本書はNext.js/ReactのWebアプリケーション開発を...',
		download: false,
		url: 'https://wings.msn.to/index.php/-/A-03/978-4-8156-1948-0/',
	}
]

export default FileLists;