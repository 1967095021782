
import '../App.css';

import DownloadIcon from '@mui/icons-material/Download';

function ListFilter({ FileLists }) {

  //filterメソッド p.103
  // src -> return 内でリスト表示
  const FilterMessage = "3500円以下の商品";
  const lowPrice = FileLists.filter(elem => elem.price < 3500);

  //sort メソッド p.105
  FileLists.sort((m, n) => m.price - n.price);

  return (
    <div>
	  <h1>{FilterMessage}</h1>
	  <dl>
		{
			lowPrice.map(elem => (
			<>
				<dt>
					<a 
						href={`${elem.url}`} 
						target="_blank"
						rel="noreferrer noopener"
					>
						{elem.title} （{elem.price}円）
					</a>
				</dt>
				<dd>{elem.summary}</dd>
			</>
			))
		}
	  </dl>

	  <h1>並び替え</h1>
	  <dl>
		{
			FileLists.map(elem => (
			<>
				<dt>
					<a 
						href={`${elem.url}`} 
						target="_blank"
						rel="noreferrer noopener"
					>
						{elem.title} （{elem.price}円）
					</a>
				</dt>
				<dd>
					{elem.summary}
					//p.108
					{elem.download && <DownloadIcon className="ButtonLink" /> }
				</dd>
			</>
			))
		}
	  </dl>

	</div>
  );
}

export default ListFilter;
