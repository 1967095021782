import React from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import '../App.css';

//FormCustomize
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

function Works() {

//規定値
const defaultValues = {
	userName: '播磨太郎',
	userMail: 'dhp.sp011@gmail.com',
	gender: '男性'
}

//form 初期化 ;/ isDirty isValid isSubmitting p.203
const {
	register,
	handleSubmit,
	watch,
	formState: { 
		errors,
		isDirty,
		isValid,
		isSubmitting
	}
} = useForm({
	defaultValues
});

// 送信する
const onSubmit = (data) => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve();
			console.log(data);
			//alert(JSON.stringify(data));
		}, 4000);
	});
}

// エラー
const onError = err => console.log(err);

const [gender, setGender] = React.useState('');

const handleChange = (event) => {
	setGender(event.target.value);
};


  return (
    <div id="Works">
		<h1>Works</h1>
    
		<form onSubmit={handleSubmit(onSubmit)}>
			<ul>
				<li><TextField 
					id="userName" 
					label="お名前" 
					variant="outlined"
					{...register("userName", { 
						required: 'お名前が入力されておりません。',
						minLength: {
							value: 2,
							message: '2文字以上で入力してください。'
						},
						maxLength: {
							value: 20,
							message: '20文字以内で入力してください。'
						},
						validate:{
							ng: (value, formValues) => {
								const ngs = ['暴力','死','グロ'];
								for (const ng of ngs){
									if (value.includes(ng)){
										return '不適切な文字が入力されています';
									}
								}
								return true;
							}
						}
					})}
					name="userName"
				/>
				{errors.userName?.message && <span className="error"><br />{errors.userName.message}</span>}
				</li>
				<li><TextField 
					id="userMail" 
					label="メールアドレス" 
					variant="outlined" 
					{...register("userMail", { 
						required: 'メールアドレスが入力されておりません。',
						minLength: {
							value: 10,
							message: '10文字以上で入力してください。'
						},
						maxLength: {
							value: 256,
							message: '256文字以内で入力してください。'
						},
						pattern: {
							value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: '正しいメールアドレスを入力してください。'
						}
					})} 
					name="userMail"
				/>
				{errors.userMail?.message && <span className="error"><br />{errors.userMail.message}</span>}
				</li>
				<li><Box sx={{ minWidth: 223 }}>
					<FormControl sx={{ minWidth: 223 }}>
						<InputLabel id="selectGenderLabel">性別</InputLabel>
						<Select
						{...register("gender",{
							required: '選択してください。'
						})}
						labelId="selectGenderLabel"
						id="selectGender"
						value={gender}
						label="gender"
						onChange={handleChange}
						>
						<MenuItem value={''}>選択</MenuItem>
						<MenuItem value={'男性'}>男性</MenuItem>
						<MenuItem value={'女性'}>女性</MenuItem>
						<MenuItem value={'未選択'}>未選択</MenuItem>
						</Select>
					</FormControl>
				</Box>
				{errors.gender?.message && <span className="error"><br />{errors.gender.message}</span>}
				</li>
			</ul>

			<p><Button 
				type="submit"
				disabled={ !isDirty || !isValid || isSubmitting }
			>送信する</Button></p>
			{isSubmitting && <div>...送信中...</div>}
		</form>
	</div>
  );
}


export default Works;
