import React from 'react';
import {useImmer} from 'use-immer';
import '../App.css';

function SimpleFormImmer() {

  /* p.181 Immer */
  const [form, setForm] = useImmer({
    name: '山田太郎',
    age: 18,
    address: {
      prefecture: '兵庫県',
      city: '姫路市'
    }
  });

  const handleNest = e => {
    const ns = e.target.name.split('.');
    setForm(form => {
      if(ns.length === 1){
        form[ns[0]] = e.target.value;
      }else{
        form[ns[0]][ns[1]] = e.target.value;
      }
    });
  };

  const show = () => {
    console.log(`お名前：${form.name}、住所：${form.address.prefecture} ${form.address.city} です。`);
  };

  return (
    <form>
    <div id="SimpleFormImmer">
		<h1>Form Immer</h1>

      <form>
        <div>
          <label htmlFor="name">名前：</label>
          <input id="name" name="name" type="text"
          onChange={handleNest} value={form.name} />
        </div>

        <div>
          <label htmlFor="prefecture">住所（都道府県）：</label>
          <input id="prefecture" name="address.prefecture" type="text"
          onChange={handleNest} value={form.address.prefecture} />
        </div>

        <div>
          <label htmlFor="city">住所（市町村）：</label>
          <input id="city" name="address.city" type="text"
          onChange={handleNest} value={form.address.city} />
        </div>

        <div>
          <button type="button" onClick={show}>送信する</button>
        </div>

      </form>


    </div>
    </form>
  );
}


export default SimpleFormImmer;
