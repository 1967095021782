
import '../App.css';

import DownloadIcon from '@mui/icons-material/Download';

function List({ FileLists }) {

  //FileLists(books) p.97
  // src -> return 内でリスト表示

  console.log(FileLists);

  FileLists.sort((m, n) => m.id - n.id);

  return (
    <div>
	  <h1>FileLists</h1>
	  <dl>
		{
			FileLists.map(elem => (
			<>
				<dt>
					<a 
						href={`${elem.url}`} 
						target="_blank"
						rel="noreferrer noopener"
					>
						{elem.title} （{elem.price}円）
					</a>
				</dt>

				//if構文 p.107
				{(() => {
					if( elem.download ){
						return <dd>{elem.summary}<DownloadIcon className="ButtonLink" /></dd>
					}else{
						return <dd>{elem.summary}</dd>
					}
				})()}
			</>
			))
		}
	  </dl>

	</div>
  );
}

export default List;
