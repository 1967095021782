
function StateCounter({ step, onUpdate }) {

  const handleClick = () => onUpdate(step);

  return (
	<button ClassName="cnt" onClick={handleClick}>
		<span>Step {step}</span>
	</button>
  );
}

export default StateCounter;
